<script setup>
import { defineEmits, onUpdated, onMounted, ref } from "vue";
import { KNOLIA } from "../knolia";
import { Gems } from "@/gems";
import { useTreeStore } from '@/store/modules/trees.js';
import DepthElement from './DepthElement.vue';
import TreeType from "../enums/TreeType";
import { MESSAGES } from "../messages";

// eslint-disable-next-line no-undef
let props = defineProps(["column", "meta_id", "is_meta"]);
let emit = defineEmits(["filter-children"]);
const store = useTreeStore();
let showOptions = ref(false);
let txtDepthName = ref();

function ChangeDepthName(column) {
  store.UpdateDepthName({
    name: txtDepthName.value.value,
    element: column,
    update: "name",
    treeType: "tasks",
  })
  showOptions.value = false
}

function DeleteColumn(column) {
  Gems.Telegram(MESSAGES.QUESTIONS.REMOVE_COLUMN_TREE, "tele-info", "", "tele-cancel").then(
    () => {
      KNOLIA.CallBackEnd("post", "delete-column", {
        column: column,
        tree: "tasks",
      }).then((res) => {
        if (res.error) {
          Gems.Telegram(MESSAGES.ERROR.DEFAULT, "tele-not-allowed")
          return
        }

        const treeIndex = store.Tree.findIndex(
          (tree) => tree.id == store.SelectedMeta.id
        );

        const levelIndex = store.Tree[treeIndex].levels.findIndex(
          (level) => level.id == column.id
        );

        store.Tree[treeIndex].levels.splice(levelIndex, 1);

        Gems.Telegram(MESSAGES.SUCCESS.REMOVED, "tele-success")
        store.UpdateTasksTree()
      });
    },
    () => {}
  );
}


function ShowOptions() {
  if(props.column.depth > props.column.totalDepth - 2 && !props.is_knowledge)
    return

  showOptions.value = true
}

function NewColumn() {
  store.NewColumn({meta_id: props.column.meta_id, depth: props.column.depth, totalDepth: props.column.totalDepth, elements: props.column.elements, treeType: TreeType.TASKS})

}

onMounted(() => {
  // Object.assign(props.column, { showSelf: KNOLIA.ToBoolean(props.column.showSelf)})

  store.setColumnsTooltip(props.column)
});

onUpdated(() => {
  store.setColumnsTooltip(props.column)
});
</script>

<template>
  <div class="depth-container">
    <div class="depth-header">
      <div class="div-column-icons">
        <i class="fa fa-plus-circle i-add-meta" :id="`new-column${column.id}`" @click="NewColumn()" v-if="column.depth <= column.totalDepth - 1"></i>

        <i class="fa fa-trash remove-col" :id="`remove-column${column.id}`" @click="DeleteColumn(column)" v-if="column.depth <= column.totalDepth - 2"></i>
      </div>

      <span class="depth" @dragover.prevent @click="store.showAllElements({depth: column.depth, meta_id: column.meta_id})"
        :id="`depth${column.depth}`">
        Nível {{ column.depth }}
      </span>

    </div>
    <div class="depth-second-header">
      <input
        :class="column.depth <= column.totalDepth - 2 ? 'col-name' : 'col-name depth-name'"
        ref="txtDepthName"
        type="text"
        :disabled="column.depth > column.totalDepth - 2"
        :value="column.designation"
        :readonly="!showOptions"
        @dblclick="ShowOptions()"
        placeholder="[Nome do nível]"
      />
      <div class="div-menu-edit" v-show="showOptions">
        <i class="fa fa-check confirm" :id="`confirm-column-name-icon-${props.column.id}`" @click="ChangeDepthName(column)"></i>
        <i class="fa fa-times cancel" :id="`cancel-column-name-icon-${props.column.id}`" @click="showOptions = false"></i>
      </div>
    </div>
  </div>
  <div class="elements-container scrollbar">
    <span v-for="(element, index) in column.elements" :key="index" class="meta-comp-item" :class="{show: element.showSelf == true || column.depth == 1}">
      <DepthElement
        :element="element"
        :column-depth="column.totalDepth"
        :meta-id="column.meta_id"
        :depth="column"
        :index="index"
        :tree-type="TreeType.TASKS"
        v-if="element.showSelf == true || props.column.depth == 1"
        @filter-children="emit('filter-children', $event)"
      />
    </span>
  </div>
</template>

<style scoped>
  @import './assets/styles/trees-style.css';
</style>
